import TextRow from "./TextRow";
import RoundShape from "./RoundShape";
import RectShape from "./RectShape";
import TextBlock from "./TextBlock";
import MediaBlock from "./MediaBlock";

export { TextRow, RoundShape, RectShape, TextBlock, MediaBlock };

export const textRow = TextRow;
export const round = RoundShape;
export const rect = RectShape;
export const text = TextBlock;
export const media = MediaBlock;
