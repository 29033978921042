import { formatCurrency } from "src/common/utils/format-currency";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/reactPlaceholder.scss";
import { placeholderStyle } from "src/common/utils/placeholder-style";
import { motion, useSpring } from "framer-motion";
import { ChevronDown } from "src/common/icons/FontAwesome/Solid/ChevronDown";
import { useEffect, useState } from "react";
import { Accordion } from "../Accordion";
import { Spinner, SpinnerColor } from "src/common/ui/Spinner";

export interface SavingsTrackerProps {
  className?: string;
  isLoading?: boolean;
  isValidating?: boolean;
  maxAnnualSavings: number;
  totalInRewards: number;
  totalInSavings: number;
  totalProcessing: number;
  hasUnreviewedSavings: boolean;
  hasAcceptedSavings: boolean;
  totalEstimated?: number;
  opened?: boolean;
  variant?: SavingsTrackerVariant;
}

export enum SavingsTrackerVariant {
  COMPACT = "compact",
  FULL = "full",
}

interface SavingsTrackerBarItemProps {
  color: string;
  width: number;
  border: boolean;
  isEndBar: boolean;
  name: string;
  value: number;
}

interface TotalSavingsProps {
  maxAnnualSavings: number;
  isLoading: boolean;
  isValidating: boolean;
}

interface SavingsTrackerBarProps {
  className?: string;
  bars: SavingsTrackerBarItemProps[];
  isLoading: boolean;
  showLimits?: boolean;
  maxAmount: number;
}

interface TotalTooltipProps {
  total: number;
  className?: string;
  totalPercentage: number;
  isLoading: boolean;
}

interface RemainingValueTextProps {
  hasUnreviewedSavings: boolean;
  remainingValue: number;
  isLoading: boolean;
}

interface LabelsProps {
  className?: string;
  bars: SavingsTrackerBarItemProps[];
  isLoading: boolean;
  opened?: boolean;
  remainingValue: number;
}

interface LabelItemProps {
  item: SavingsTrackerBarItemProps;
}

interface NoNewSavingsMessageProps {
  className?: string;
}

function TotalSavings({
  maxAnnualSavings,
  isLoading,
  isValidating,
}: TotalSavingsProps) {
  const [total, setTotal] = useState<number>(maxAnnualSavings);

  // avoids showing 0 when is re-validating the total
  useEffect(() => {
    if (!isValidating && !isLoading && maxAnnualSavings !== total) {
      setTotal(maxAnnualSavings);
    }
  }, [maxAnnualSavings, isValidating, isLoading, total]);
  return (
    <ReactPlaceholder
      showLoadingAnimation={true}
      ready={!isLoading}
      type="rect"
      style={{
        ...placeholderStyle,
        height: 35,
        marginTop: 10,
        marginBottom: 7,
        width: "50%",
        borderRadius: 20,
        background: "#efefef",
      }}
    >
      {total > 0 ? (
        <motion.strong
          className="text-center text-rewards-700 text-semibold text-[25px] lg:text-[35px] mt-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: isValidating ? 0.5 : 1 }}
          transition={{ duration: isValidating ? 0.5 : 0.1 }}
        >
          {formatCurrency(total, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}{" "}
          a year
        </motion.strong>
      ) : (
        <motion.strong
          className="text-center text-blueNews-500 text-semibold text-[15px] lg:text-[25px] mt-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: isValidating ? 0.5 : 1 }}
          transition={{ duration: isValidating ? 0.5 : 0.1 }}
        >
          Enjoy $0 copay and free delivery on eligible prescriptions
        </motion.strong>
      )}
    </ReactPlaceholder>
  );
}

function SavingsTrackerBar({
  className = "",
  bars,
  isLoading,
  showLimits = false,
  maxAmount = 0,
}: SavingsTrackerBarProps) {
  const total = bars.reduce((count, value) => (count += value.width), 0);
  const totalDuration = 0.5;

  function getTransitionForIndex(
    bar: SavingsTrackerBarItemProps,
    index: number,
  ) {
    let ease = "linear";
    if (index === bars.length - 1) {
      ease = "circOut";
    } else if (index === 0) {
      ease = "linear";
    } else {
      ease = "linear";
    }

    // count the previous values for delay
    const delay =
      (bars
        .slice(0, index)
        .reduce((count, value) => (count += value.width), 0) /
        total) *
      totalDuration;

    return {
      ease,
      duration: (bar.width / total) * totalDuration,
      delay: delay,
    };
  }

  return (
    <>
      {!isLoading && showLimits && (
        <div
          className={`w-full flex justify-space-between mb-1 ${
            showLimits ? className : ""
          }`}
        >
          <span className="text-gray-950 font-semibold">$0</span>
          <span className="text-gray-950 font-semibold ml-auto">
            {formatCurrency(maxAmount, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
          </span>
        </div>
      )}
      <div
        className={`h-[10px] lg:h-[20px] w-full bg-gray-100 rounded-[10px] ${
          showLimits ? "" : className
        } overflow-hidden relative flex items-start`}
      >
        {!isLoading &&
          bars &&
          bars.map((bar: SavingsTrackerBarItemProps, index: number) => {
            const style = {
              width: `${Math.ceil(bar.width * 100)}%`,
              background: bar.color,
              borderTopRightRadius: bar.isEndBar ? "10px" : 0,
              borderBottomRightRadius: bar.isEndBar ? "10px" : 0,
              border: bar.border ? "1px dashed #48258D" : "",
              zIndex: bar.border ? "1" : "2",
              borderTopLeftRadius: index === 0 ? "10px" : 0,
              borderBottomLeftRadius: index === 0 ? "10px" : 0,
              transformOrigin: "top left",
            };
            return (
              <motion.div
                initial={{ width: "0%", x: -3 }}
                animate={{ width: `${Math.ceil(bar.width * 100)}%`, x: 0 }}
                transition={getTransitionForIndex(bar, index)}
                key={`bar-${index}`}
                className="h-full"
                style={style}
              />
            );
          })}
      </div>
    </>
  );
}

function TotalTooltip({
  total,
  className = "",
  isLoading,
  totalPercentage = 0,
}: TotalTooltipProps) {
  const triangleClasses =
    "before:absolute before:-top-[11px] before:left-[50%] before:-ml-[11px] before:origin-top before:scale-y-[70%] before:content-[''] before:border-rewards-900 before:w-0 before:h-0 before:border-[10px] before:border-l-transparent before:border-r-transparent before:border-t-transparent";
  return (
    <ReactPlaceholder
      ready={!isLoading}
      type="rect"
      style={{ height: 35, background: "#fff" }}
    >
      <motion.span
        initial={{ x: "-50%", left: 0 }}
        animate={{ left: `calc(${totalPercentage * 100}% + 2px)` }}
        transition={{ duration: 0.5, ease: "circOut" }}
        className={`relative mt-[6px] text-white rounded-[30px] bg-rewards-900 px-[10px] py-[7px] self-start text-xs text-medium ${triangleClasses} ${className}`}
      >
        {formatCurrency(total, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}
      </motion.span>
    </ReactPlaceholder>
  );
}

function LabelItem({ item }: LabelItemProps) {
  const style = {
    width: "20px",
    height: "10px",
    background: item.color,
    borderRadius: "10px",
    border: item.border ? "1px dashed #48258D" : "",
  };
  return (
    <div className="h-full flex flex-col items-start grow-[1] basis-1/2 mb-6">
      <div className="flex items-center">
        <div style={style} className="shrink-[0]" />
        <div className="text-rewards-800 font-semibold ml-1.5 text-base lg:text-[18px]">
          {formatCurrency(item.value, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </div>
      </div>
      <span className="text-left text-[10px] font-normal text-gray-950 ml-[25px] lg:text-[12px]">
        {item.name}
      </span>
    </div>
  );
}

function Labels({
  className = "",
  bars,
  isLoading,
  opened = false,
  remainingValue,
}: LabelsProps) {
  const unreviewedObject = {
    color: "#EDEEF1",
    width: 0,
    border: false,
    isEndBar: false,
    name: "Unreviewed",
    value: remainingValue,
  };

  const barsWithUnreviewed = [...bars, unreviewedObject];

  const [isOpened, setIsOpened] = useState(opened);
  const rotate = useSpring(0, { damping: 10.0, mass: 1 });

  useEffect(() => {
    rotate.set(isOpened ? 180 : 0, true);
  }, [isOpened, rotate]);

  return (
    <>
      <ReactPlaceholder
        type="rect"
        ready={!isLoading}
        style={{
          ...placeholderStyle,
          height: 68,
          borderRadius: 10,
          marginTop: 15,
          marginBottom: 3,
          background: "#efefef",
        }}
      >
        <motion.div
          className={`w-full ${className} relative py-2.5 border-y`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
        >
          <div
            className="text-sm uppercase font-semibold text-gray-950 tracking-tight cursor-pointer flex"
            onClick={() => setIsOpened(!isOpened)}
          >
            UNDERSTAND YOUR TRACKER
            <motion.div className="ml-auto" style={{ rotate }}>
              <ChevronDown className="h-4 w-4" />
            </motion.div>
          </div>
          <Accordion isOpen={isOpened}>
            <div
              className={`transition-all duration-[0.5s] flex justify-space-between flex-wrap rounded-[10px] box-border p-5 pb-0 ${
                isOpened
                  ? "mt-2.5 opacity-[1] translate-y-[0px]"
                  : "opacity-[0] translate-y-[30px]"
              }`}
            >
              {barsWithUnreviewed &&
                barsWithUnreviewed.map(
                  (bar: SavingsTrackerBarItemProps, index: number) => (
                    <LabelItem item={bar} key={index} />
                  ),
                )}
            </div>
          </Accordion>
        </motion.div>
      </ReactPlaceholder>
    </>
  );
}

function RemainingValueText({
  hasUnreviewedSavings,
  remainingValue,
  isLoading,
}: RemainingValueTextProps) {
  return (
    hasUnreviewedSavings && (
      <ReactPlaceholder
        showLoadingAnimation={true}
        ready={!isLoading}
        type="text"
        rows={2}
        style={{
          ...placeholderStyle,
          height: 38,
          marginTop: 18,
          marginBottom: 7,
          width: "100%",
          borderRadius: 1,
        }}
      >
        <motion.span
          className="font-normal text-base my-2.5 w-[calc(100%+24px)] lg:w-full lg:ml-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1 }}
        >
          You still have{" "}
          {remainingValue > 0 ? (
            <>
              <strong>{`${formatCurrency(remainingValue, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 0,
              })} in potential savings`}</strong>
              . Review your opportunities to save more!
            </>
          ) : (
            <>
              <strong>opportunities to review</strong>. Review them now to save
              more time and money!
            </>
          )}
        </motion.span>
      </ReactPlaceholder>
    )
  );
}

function NoNewSavingsMessage({ className = "" }: NoNewSavingsMessageProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, ease: "circOut" }}
      className={`rounded-[20px] text-center text-balance text-black p-5 border-counterGreen-600 bg-white shrink-[0] ${className}`}
    >
      <div className="text-sm p-5 bg-gray-50 rounded-[15px]  border-1 border-rewards-100">
        Stay tuned! You have no savings at this time.
        <br className="hidden lg:block" />
        <span className="lg:hidden"> </span>When we find you new savings or
        rewards, we’ll alert you to check back.
      </div>
    </motion.div>
  );
}

export function SavingsTracker({
  className = "",
  isLoading = false,
  isValidating = false,
  maxAnnualSavings = 0,
  totalInRewards,
  totalInSavings,
  totalProcessing,
  hasUnreviewedSavings,
  hasAcceptedSavings,
  totalEstimated = 0,
  opened = false,
  variant = SavingsTrackerVariant.FULL,
}: SavingsTrackerProps) {
  const isFull = variant === SavingsTrackerVariant.FULL;
  function getBarsValue() {
    const returnArray: SavingsTrackerBarItemProps[] = [];

    if (variant === SavingsTrackerVariant.FULL) {
      returnArray.push({
        width: totalInRewards / maxAnnualSavings,
        color: "#A693F2",
        border: false,
        isEndBar: false,
        value: totalInRewards,
        name: "Processed Rewards",
      });
      returnArray.push({
        width: totalInSavings / maxAnnualSavings,
        color: "#562BAC",
        border: false,
        isEndBar: false,
        value: totalInSavings,
        name: "Processed Savings",
      });
      returnArray.push({
        width: totalProcessing / maxAnnualSavings,
        color: "#E9E6FC",
        border: true,
        value: totalProcessing,
        isEndBar: false,
        name: "Processing",
      });
    } else {
      returnArray.push({
        width: totalEstimated / maxAnnualSavings,
        color: "#E9E6FC",
        border: true,
        value: totalProcessing,
        isEndBar: false,
        name: "Processing",
      });
    }

    if (returnArray.length > 0) {
      returnArray[returnArray.length - 1].isEndBar = true;
    }
    return returnArray;
  }
  const barsValue = getBarsValue();
  const totalDone = totalInRewards + totalInSavings;
  const globalTotal =
    variant === SavingsTrackerVariant.FULL
      ? totalDone + totalProcessing
      : totalEstimated;
  const remainingValue = maxAnnualSavings - globalTotal;

  let baseClass =
    "rounded-[20px] bg-white py-5 px-8 flex flex-col items-stretch lg:py-8 shrink-[0]";

  if (variant === SavingsTrackerVariant.COMPACT) {
    baseClass = "py-2 flex flex-col items-stretch  shrink-[0]";
  }

  const hasAnySavings = hasAcceptedSavings || hasUnreviewedSavings;

  return (
    <div
      className={`relative flex flex-col items-stretch w-full shrink-[0] ${className}`}
    >
      {(hasAnySavings || isLoading || (isValidating && hasAnySavings)) && (
        <div className={baseClass}>
          <Spinner
            color={SpinnerColor.PURPLE}
            className={`absolute transition w-[40px] h-[40px] top-5 right-8 lg:top-8 ${
              isValidating && !isLoading
                ? "opacity-1"
                : "opacity-0 delay-[0.5s]"
            }`}
          />

          {isFull && (maxAnnualSavings > 0 || hasAcceptedSavings) && (
            <>
              <span
                className={`transition-all text-center text-[10px] uppercase font-semibold lg:text-[12px] ${
                  isLoading ? "text-gray-300" : ""
                }`}
              >
                your potential savings and rewards this year
              </span>

              <TotalSavings
                maxAnnualSavings={maxAnnualSavings}
                isLoading={isLoading}
                isValidating={isValidating}
              />
            </>
          )}

          {maxAnnualSavings > 0 && (
            <>
              <SavingsTrackerBar
                className="mt-1 lg:mt-5"
                bars={barsValue.filter((bar) => bar.width > 0)}
                isLoading={isLoading}
                showLimits={!isFull}
                maxAmount={maxAnnualSavings}
              />

              <TotalTooltip
                total={globalTotal}
                isLoading={isLoading}
                totalPercentage={globalTotal / maxAnnualSavings}
                className={isLoading ? "opacity-0" : "opacity-1"}
              />
            </>
          )}

          {isFull && (
            <>
              {maxAnnualSavings > 0 && (
                <Labels
                  bars={barsValue}
                  className="mt-2.5 lg:mt-5 w-[calc(100%+24px)] lg:w-full lg:ml-0"
                  isLoading={isLoading}
                  opened={opened}
                  remainingValue={remainingValue}
                />
              )}

              <RemainingValueText
                hasUnreviewedSavings={hasUnreviewedSavings}
                remainingValue={remainingValue}
                isLoading={isLoading}
              />
            </>
          )}
        </div>
      )}

      {((!isLoading && !hasAnySavings && isFull && !isValidating) ||
        (isValidating && !hasAnySavings && !isLoading)) && (
        <NoNewSavingsMessage />
      )}
    </div>
  );
}
