import(/* webpackMode: "eager" */ "/molar/frontend/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/molar/frontend/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/molar/frontend/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/molar/frontend/src/app/landing/layout.tsx");
;
import(/* webpackMode: "eager" */ "/molar/frontend/src/common/components/Button/index.tsx");
;
import(/* webpackMode: "eager" */ "/molar/frontend/src/common/ui/Eyebrow/styles.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Stars"] */ "/molar/frontend/src/landing/components/icons/Stars.tsx");
;
import(/* webpackMode: "eager" */ "/molar/frontend/src/landing/components/PhoneGreen/index.tsx");
